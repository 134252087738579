
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import theme from './theme';
import AppBar from './AppBar';
import Description from './Description';
import ObrasSociales from './ObrasSociales';
import Map from './Map';
import Contact from './Contact';
import WhatsAppIcon from './whatsapp.png';

const WhatsAppIconContainer = styled('div')( () => ({
  position: 'fixed',
  bottom: '50px',
  right: '20px',
  zIndex: 999,
  'img': {
    width: '64px',
    height: '64px',
  },
}));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <WhatsAppIconContainer
        sx={{
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Button href='https://wa.me/+5491123875033'>
          <img src={WhatsAppIcon} alt='whatsapp'/>
        </Button>
      </WhatsAppIconContainer>
      <AppBar />
      <Description />
      <ObrasSociales />
      <Map />
      <Contact />
    </ThemeProvider>
  );
}

export default App;
