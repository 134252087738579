
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIconImg from './WhatsAppWhite.png';
import IGIconImg from './ig-icon.png'
import { styled } from '@mui/material/styles';
import LinkDiv from './LinkDiv';


const ContactContainer = styled(Box)( ({ theme }) => ({
  backgroundColor: theme.farmaciapablo.colors.green,
  minHeight: '100px',
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridTemplateRows: 'repeat(1,1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridTemplateRows: 'repeat(1, 1fr)',
  },
  gridAutoFlow: 'row',
})); 

const StyledButton = styled(Button)( ({ theme }) => ({
  color: 'white',
  fontSize: '1.1rem',
  paddingRight: '32px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'inherit',
    fontWeight: 700
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'left'
  }
}));

const WhatsAppIconContainer = styled('div')( () => ({
  display: 'inline-block',
  'img': {
    width: '20px',
    height: '20px',
  },
}));

const IGIconContainer = styled('div')( () => ({
  display: 'inline-block',
  background: 'transparent',
  'img': {
    background: 'transparent',
    width: '20px',
    height: '20px',
  },
}));

const WhatsAppIcon = () => (
  <WhatsAppIconContainer>
    <img src={WhatsAppIconImg} alt='WhatsApp'/>
  </WhatsAppIconContainer>
)

const IGIcon = () => (
  <IGIconContainer>
    <img src={IGIconImg} alt='Instagram'/>
  </IGIconContainer>
)

const Contact = () => (
  <Box>
    <LinkDiv section='contact'/>  
    <ContactContainer
      sx={ {
        p: 4,
        pl: {
          xs: 8
        }
      }}
    >
      <Box
        alignContent='center'
        justifyContent='center'
        sx={{ display: { xs: 'block', sm: 'flex' }}}
      >
        <StyledButton startIcon={ <CallIcon />} href="tel:52413780">
          5241-3780
        </StyledButton>
        <StyledButton startIcon={ <WhatsAppIcon />} href="tel:0111523875033">
            011-15-2387-5033
        </StyledButton>
        <StyledButton startIcon={ <MailOutlineIcon />} href="mailto:info@farmaciapablo.com.ar">
          info@farmaciapablo.com.ar
        </StyledButton>
        <StyledButton startIcon={ <IGIcon />} href="https://instagram.com/farmaciapablo_oficial" target="_blank">
          farmaciapablo_oficial
        </StyledButton>
      </Box>
      {/* <Box
        display='flex'
        alignContent='center'
        justifyContent='center'
      >
        <StyledButton startIcon={ <WhatsAppIcon />} href="tel:0111523875033">
            011-15-2387-5033
        </StyledButton>
      </Box>
      <Box
        display='flex'
        alignContent='center'
        justifyContent='center'
      >
        <StyledButton startIcon={ <AlternateEmailIcon />} href="mailto:info@farmaciapablo.com.ar">
          info@farmaciapablo.com.ar
        </StyledButton>
      </Box>
      <Box
        display='flex'
        alignContent='center'
        justifyContent='center'
      >
        <StyledButton startIcon={ <IGIcon />} href="https://instagram.com/farmaciapablo_oficial" target="_blank">
          farmaciapablo_oficial
        </StyledButton>
      </Box> */}
    </ContactContainer>
  </Box>
);

export default Contact;