import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')( () => ({
  display: 'block',
  position: 'relative',
  top: '-166px',
  visibility: 'hidden',
}));

const LinkDiv = ({ section }) => (<StyledDiv id={section}></StyledDiv> );

export default LinkDiv;