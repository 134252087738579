import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  spacing: 8,
  farmaciapablo: {
    colors: {
      green: '#05401F',
      greenLight: '#A8DDAD',
      menuText: '#505050',
      background: {
        grayLight: '#f8f9fa'
      }
    }
  },
  typography: {
    h5: {
      color: '#05401F',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;