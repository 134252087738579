import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CallIcon from '@mui/icons-material/Call';

import { styled } from '@mui/material/styles';
import Logo from './logo.png';
import WhatsAppIcon from './whatsapp.png';

const StyledAppBar = styled(MuiAppBar)( () => ({
  backgroundColor: 'white',
}));

const StyledButton = styled(Button)( ({ theme }) => ({
  color: theme.farmaciapablo.colors.menuText,
  fontSize: '1.2rem',
  paddingRight: '32px',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: 'inherit',
    fontWeight: 700,
  }
}));

const SupraButton = styled(Button)( ({ theme }) => ({
  color: 'white',
  fontSize: '2rem',
  paddingRight: '32px',
  fontWeight: 700,
  '&:hover': {
    backgroundColor: 'inherit',
    fontWeight: 700,
  },
  'svg': {
    fontSize: '2rem !important',
  }
}));

const StyledIcon = styled(MenuIcon)( ({ theme }) => ({
  color: theme.farmaciapablo.colors.menuText,
  fontSize: '2.5rem',
})); 

const LogoImgContainer = styled('div')( () => ({
  'img': {
    width: '220px',
    height: '45px',
  },
  padding: '16px',
}));

const SupraMenu = styled(Box)( ({theme}) => ({
  backgroundColor: theme.farmaciapablo.colors.green,
  height: '54px',
  width: '100%',
  display: 'flex'
}));

const WhatsAppIconContainer = styled('div')( () => ({
  display: 'inline-block',
  'img': {
    width: '50px',
    height: '50px',
  },
  marginLeft: '10px',
}));

const Offset = styled('div')(({theme}) => ({
  minHeight: '166px',
  [theme.breakpoints.down('md')]: {
    minHeight: '80px',
  }
}));
const drawerWidth = 240;
const navItems = [
  { text: 'Obras Sociales', to: 'obrasSociales'},
  { text: 'Dónde Estamos', to: 'dondeEstamos' },
  { text: 'Contacto', to: 'contact' }];
  

const AppBar = (props) => {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left' }}>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.to} disablePadding>
            <ListItemButton sx={{ textAlign: 'left', pl: '24px' }} href={`#${item.to}`}>
              <ListItemText
                primary={item.text}
                sx={
                  {
                    '& .MuiTypography-body1':{
                      fontSize: '1.2rem'
                    }
                  }
                }/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledAppBar position="fixed">
        <SupraMenu justifyContent="end" sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <SupraButton startIcon={ <CallIcon />} href="tel:52413780">
            5241-3780
          </SupraButton>
        </SupraMenu>
        <Toolbar sx={{ mb: 2, mt: 2}}>
          <StyledIcon
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </StyledIcon>
          <LogoImgContainer sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1 }}>
            <img alt='FarmaciaPablo' src={Logo} />
          </LogoImgContainer>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <StyledButton key={item.to} href={`#${item.to}`}>
                {item.text}
              </StyledButton>
            ))}
            <StyledButton href='https://wa.me/+5491123875033'>
              <WhatsAppIconContainer>
                <img src={WhatsAppIcon} alt='whatsapp'/>
              </WhatsAppIconContainer>
            </StyledButton>
          </Box>
          <LogoImgContainer sx={{ display: { xs: 'block', sm: 'none' }, margin: "auto" }}>
            <img alt='FarmaciaPablo' src={Logo} style={{ margin: 0 }}/>
          </LogoImgContainer>
        </Toolbar>
      </StyledAppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Offset />
    </Box>
  );
}

export default AppBar;