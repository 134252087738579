import * as React from 'react';
import Box from '@mui/material/Box';
import LinkDiv from './LinkDiv';

const Map = () => (
  <Box
    sx={{  pb: 8 }}
  >
    <LinkDiv section='dondeEstamos'/>
    <iframe
      title='GoogleMap'
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.039999043878!2d-58.56590652365663!3d-34.62842947294542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb98f1a89c149%3A0x7b10baca09abb0d5!2sFarmacia%20Pablo!5e0!3m2!1sen!2sar!4v1685825010013!5m2!1sen!2sar"
      width="100%"
      height="500"
      style={{border: 0}}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade" />
  </Box>
);

export default Map;