import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import AvalianLogo from './ObrasSociales/avalian_logo-color.png';
import PFALogo from './ObrasSociales/escudo_pfa_80.png';
import MedicusLogo from './ObrasSociales/Medicus_argentina_logo.png';
import MedifeLogo from './ObrasSociales/medife-logo-2018.jpeg';
import OmintLogo from './ObrasSociales/omint-logo-3.png';
import OSPJNLogo from './ObrasSociales/ospjn.png';
import PasteurLogo from './ObrasSociales/pasteur.png';
import SancordSaludLogo from './ObrasSociales/sancor_salud.svg';
import SwissMedicalLogo from './ObrasSociales/swiss_medical.png';
import GalenoLogo from './ObrasSociales/logoGaleno700.webp';
import AccordLogo from './ObrasSociales/logo-accord.png';
import OSSEGLogo from './ObrasSociales/OSSEG.png';
import IOAMALogo from './ObrasSociales/IOMA.png';
import PAMILogo from './ObrasSociales/pami.png';
import LinkDiv from './LinkDiv';

const LogoImg = styled('img')( () => ({
  height: '50px',
}));

const SamllLogoImg = styled('img')( () => ({
  height: '40px',
}));

const MediumLogoImg = styled('img')( () => ({
  height: '65px',
}));

const BigLogoImg = styled('img')( () => ({
  height: '85px',
}));

const LogoContainers = styled('div')( ({theme}) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  gridAutoFlow: 'row',
  gap: 40,
  width: '85%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    marginLeft: '56px',
  },
  'div': {
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  }
}));

const ObrasSociales = () => (
  <Box
    sx={{
      pt: {
        xs: 7,
        md: 12
      },
      pb: {
        xs: 7,
        md: 12
      },
    }}
  >
    <LinkDiv section='obrasSociales'/>
    <LogoContainers>
      <div>
        <LogoImg src={SwissMedicalLogo} alt="SwissMedical Logo" />
      </div>
      <div>
        <SamllLogoImg src={GalenoLogo} alt="Galeno Logo"/>
      </div>
      <div>
        <LogoImg src={OmintLogo} alt="Omint Logo" />
      </div>
      <div>
        <MediumLogoImg src={PasteurLogo} alt="Pasteur Logo" />
      </div>
      <div>
        <LogoImg src={AvalianLogo} alt="Avian Logo" />
      </div>
      <div>
        <MediumLogoImg src={PFALogo} alt="PFA Logo" />
      </div>
      <div>
        <SamllLogoImg src={MedicusLogo} alt="Medicus Logo" />
      </div>
      <div>
        <LogoImg src={MedifeLogo} alt="Medife Logo" />
      </div>
      <div>
        <MediumLogoImg src={OSPJNLogo} alt="OSPJN Logo" />
      </div>
      <div>
        <MediumLogoImg src={SancordSaludLogo} alt="SancordSalud Logo"/>
      </div>
      <div>
        <MediumLogoImg src={AccordLogo} alt="UPAccordSalud Logo"/>
      </div>
      <div>
        <MediumLogoImg src={OSSEGLogo} alt="OSSEG Logo"/>
      </div>
      <div>
        <BigLogoImg src={IOAMALogo} alt="IOMA Logo"/>
      </div>
      <div>
        <MediumLogoImg src={PAMILogo} alt="PAMI Logo"/>
      </div>
    </LogoContainers>
  </Box>
);

export default ObrasSociales;