import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const StyledBoxContainer = styled(Box)( ({ theme }) => ({
  backgroundColor: theme.farmaciapablo.colors.greenLight,
  '.MuiTypography-root': {
    color: theme.farmaciapablo.colors.green,
  },
  '.MuiTypography-h1': {
    fontWeight: 700,
  },
  '.MuiTypography-h3': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.7rem'
    }
  }
}));

const Description = () => (
  <StyledBoxContainer sx={{ flexGrow: 1, p: 8 }}>
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          pl: {
            xs: '0 !important'
          },
        }}
        >
        <Box
          sx={{
            pl: {
              md: 15
            },
            pt: {
              xs: 0,
              md: 8
            },
            pb: {
              xs: 0,
              md: 8
            },
          }}
        >
          {/* <Typography variant="h3">
            Todo lo que buscás en
          </Typography>
          <Typography
            variant="h1">
            perfumeria, belleza y
          </Typography>
          <Typography
            variant="h1">
            maternidad.
          </Typography> */}
          <Typography variant="h3">
            Encontranos en
          </Typography>
          <Typography
            variant="h3">
            Av. Republica 1839 - Ciudadela
          </Typography>
          <Typography
            pt='30px' 
            variant="h5">
            Horarios:
          </Typography>
          <Typography
            sx={{
              pt: {
                xs: 1
              }
            }}
            variant="h5">
            Lunes a Viernes de 9 a 13 hs y 16 a 20 hs
          </Typography>  
          <Typography variant="h5"
            sx={{
              pt: {
                xs: 1
              }
            }}>
            Sábados de 9 a 13 hs
          </Typography>  
        </Box>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <Box
          sx={{
            pl: {
              xs: 6,
              md:8
            },
            pt: 10,
            pb: 8
          }}
        >
          <LogoImg alt="FarmaciaPablo" src={Logo02} />
        </Box>
      </Grid> */}
    </Grid>
  </StyledBoxContainer>
);

export default Description;